.scroll-snap {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 5rem;
  left: 0;
  right: 0;
  scroll-snap-type: both mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  outline: none;
}

section {
  height: 100%;
  position: relative;
  scroll-snap-align: start;
  font-family: "Mollen";
}

section h1 {
  margin: 0;
  text-shadow: black 0 0 20px;
  font-weight: bold;
  display: inline-block;
}

section h1.heading {
  font-size: 9vmin;
  top: 5vh;
  left: 9vmin;
  position: relative;
}

section h1.title {
  transform: translate(-50%, -50%);
  top: 40vh;
  left: 50vw;
  position: relative;
  font-size: 20vmin;
}

.bg-image {
  transition: opacity 0.3s;
  opacity: 0;
  z-index: -10;
  position: fixed;
  top: 0;
  bottom: 5rem;
  left: 0;
  right: 0px;
  background: black;
}

.bg-image-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg-image img,
.bg-image video {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0px;
  object-fit: cover;
  color: transparent;
}

.card {
  transform: translateY(-50%);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  justify-content: center;
  display: flex;
  flex-flow: row;
  text-shadow: black 0 0 5px;
  padding: 5vmin;
  font-size: 4.5vmin;
}

.card b {
  text-shadow: 0 0 10px #000, 0 0 7px #ffffff, 0 0 10px #000;
}

.card p {
  margin: 0;
}

.vertical-50 {
  width: 100%;
  position: absolute;
}
