
.overlay {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 5rem;
  left: 0;
}

.background {
  width: 100%;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("images/splash.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: none;
}