@font-face {
  font-family: "Lato";
  src: url("fonts/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("fonts/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mollen";
  src: url("fonts/Mollen-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mollen";
  src: url("fonts/Mollen-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: rgba(50, 50, 50, 0.5);
}

::-webkit-scrollbar-thumb {
  background: #111;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body {
  color: #ffffff;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Lato";
}

a {
  color: white;
}

.team-member {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20vh;
  position: relative;
  top: 20vh;
  text-align: left;
  display: flex;
  flex-flow: row;
}

.info-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  background: rgb(35, 35, 35);
}

.pull-left .info-container {
  padding-left: 315px;
}

.pull-right .info-container {
  padding-right: 315px;
}

.info {
  padding: 6vh;
}

.info h1 {
  font-size: 3vh;
  font-weight: 900;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.info p,
.info ul,
.info h2 {
  font-size: 3vmin;
  margin: 0;
  line-height: 1.3;
  color: rgb(230, 230, 230);
}

.photo-container {
  position: relative;
  top: -35px;
}

.pull-left .photo-container {
  left: 315px;
  margin-left: -350px;
  order: 1;
}

.pull-right .photo-container {
  margin-right: -350px;
  right: 315px;
  order: 2;
}

.photo {
  position: relative;
  width: 30vh;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
}

.content-wrap {
  position: relative;
  /* Same as footer height */
  padding-bottom: 5rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.contact-container {
  background: linear-gradient(transparent, black);
  text-align: center;
  width: 100%;
  height: 10vh;
  margin: auto;
  margin-bottom: 0;
  pointer-events: none;
}

.contact {
  margin: 25px;
  position: relative;
  font-size: 2.5rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  z-index: 1;
  pointer-events: initial;
}

.footer {
  margin: 0;
  padding: 0;
  background: rgb(16, 16, 16);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  color: white;
}

.footer-content {
  margin: 2rem;
}

.footer-splash {
  background: rgb(16, 16, 16);
  height: 15vh;
  position: relative;
  top: -5vh;
  margin-bottom: -15vh;
  margin-left: auto;
  margin-right: auto;
  -webkit-mask-image: url("images/silhouette.png");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: bottom center;
  mask-image: url("images/silhouette.png");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: bottom center;
}

.headline {
  top: 10vh;
  position: relative;
  width: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.headline .logo {
  display: flex;
  flex: 1 0;
  text-align: center;
  margin: auto;
  margin-top: 4vh;
  margin-bottom: 4vh;
  font-size: 5vw;
  margin-left: 5vw;
  font-weight: bold;
  font-family: "Mollen";
}

.headline .text {
  display: flex;
  flex: 1 0;
  font-size: 3.5vw;
  width: 40vw;
  margin: 0.6vw;
}

.headline ul {
  margin: 0;
}
.headline li {
  list-style-type: none;
}

@media screen and (max-width: 1400px) {
  .headline {
    flex-direction: column;
  }
  .headline .logo {
    font-size: 5vh;
    margin-top: 5vh;
  }
  .headline .text {
    font-size: 3.5vh;
    width: initial;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 0;
    margin-bottom: 5vh;
  }
  .team-member {
    flex-direction: column;
  }
  .pull-right .info-container {
    padding-right: 0px;
  }
  .pull-right .photo-container {
    margin-right: auto;
    margin-left: auto;
    right: initial;
    order: initial;
  }
  .photo-container {
    top: initial;
    bottom: -35px;
  }
}
